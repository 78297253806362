import {AssetType, OperationModeType} from '@hconnect/common/types'
import {formatCurrency} from '@hconnect/uikit/src/common'
import {Typography, SxProps, useTheme, styled} from '@mui/material'
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'

import {getAmpelColorStyle} from '../../../../../shared/ampel.styles'
import {PlannerNotification} from '../../../../../shared/components/PlannerNotification'
import {PriceLevel} from '../../../../../shared/enums'
import type {Comment, ScheduleItem as ScheduleItemType} from '../../../../../shared/interfaces/api'
import {AomAssetIcon} from '../../AomAssetIcon'

export const StyledScheduleItemLabelDiv = styled('div')(({theme}) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '100%',
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  '& p': {
    marginRight: theme.spacing(1)
  }
}))

interface ScheduleItemLabelProps {
  assetType: AssetType
  operationModeType: OperationModeType
  item: ScheduleItemType
  operationModeName: string
  shouldShowExactPrice: boolean
  priceLevel: PriceLevel | undefined
  costPerMWh: number
  currency: string
  comment?: Comment
}
export const ScheduleItemLabel = memo(
  React.forwardRef<HTMLDivElement, ScheduleItemLabelProps>(
    (
      {
        assetType,
        operationModeType,
        item,
        operationModeName,
        shouldShowExactPrice,
        priceLevel,
        costPerMWh,
        currency,
        comment
      },
      ref
    ) => {
      const theme = useTheme()
      const {
        t,
        i18n: {language}
      } = useTranslation()

      return (
        <StyledScheduleItemLabelDiv ref={ref}>
          <AomAssetIcon
            id="label_icon"
            assetType={assetType}
            aomType={operationModeType}
            isTransition={item.isTransitionTime}
            isFixed={item.isFixed}
            pl={0}
          />
          <Typography variant="subtitle1" id="label_text" noWrap>
            {operationModeName}
            {shouldShowExactPrice && (
              <Typography
                component="span"
                variant="subtitle1"
                sx={{...(getAmpelColorStyle(priceLevel) as SxProps), ml: theme.spacing(1)}}
              >
                {`${t('common.amountInCurrencyPerMWh', {
                  amount: formatCurrency(costPerMWh, 2, language),
                  currency: currency
                })}`}
              </Typography>
            )}
          </Typography>
          {/* Comment notification */}
          {comment && (
            <PlannerNotification
              id="comment_notification"
              value={1}
              testId="comment_notification"
              styles={{
                notification: {
                  position: 'relative',
                  border: 'none',
                  marginLeft: theme.spacing(1)
                }
              }}
            />
          )}
        </StyledScheduleItemLabelDiv>
      )
    }
  )
)
ScheduleItemLabel.displayName = 'ScheduleItemLabel'
