import {AssetSnapshot, AssetType} from '@hconnect/common/types'
import {flow} from 'lodash'
import {Moment} from 'moment-timezone'
import {useCallback, useMemo} from 'react'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {getAssetsFromHistoryByDate, sortAssets} from '../../../selectors/assets'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

type AssetSelector = (assets: AssetSnapshot[]) => AssetSnapshot[]

const excludeBaseLoad = (assets: AssetSnapshot[]) =>
  assets.filter((asset) => asset.type !== AssetType.BaseLoad)

interface HistoryAssetsQueryParams {
  timeFrame: MomentRange
  // date which indicates point in time for which assets should be selected from history
  date?: Moment
  sorted?: boolean
  isBaseLoadExcluded?: boolean
  useErrorBoundary?: boolean
}
/**
 * hook to retrieve assets from assets history
 * base load asset is excluded by default
 */
export const useHistoryAssetsQuery = ({
  timeFrame: [from, to],
  date,
  sorted = false,
  isBaseLoadExcluded = true,
  useErrorBoundary = true
}: HistoryAssetsQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const selectors: AssetSelector[] = useMemo(
    () => [
      // excluding baseLoad by default
      ...(isBaseLoadExcluded ? [excludeBaseLoad] : []),
      ...(sorted ? [sortAssets] : [])
    ],
    [isBaseLoadExcluded, sorted]
  )

  const combinedSelector = useCallback(
    (assets: AssetSnapshot[]) => flow(...selectors)(assets) as AssetSnapshot[],
    [selectors]
  )
  return usePlannerQuery('assetsHistory', [plantCode, from.toISOString(), to.toISOString()], {
    select: (assetsHistory) => {
      const assetsFromHistory = getAssetsFromHistoryByDate(assetsHistory, date ?? to)
      return combinedSelector(assetsFromHistory)
    },
    useErrorBoundary
  })
}
