import {AssetType, OperationModeType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {LockOutlined, BuildOutlined} from '@mui/icons-material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import {Box, BoxProps, SvgIconProps} from '@mui/material'
import React from 'react'

import {CementIcon} from '../../../assets/CementIcon'
import {ClinkerIcon} from '../../../assets/ClinkerIcon'
import {HCEMPalette} from '../../../shared/pallette'

const iconStyle: SvgIconProps = {
  style: {fontSize: '1rem'},
  fill: HCEMPalette.deepOcean
}
const AssetToIcon: Record<AssetType, JSX.Element> = {
  [AssetType.Crusher]: <PowerSettingsNewIcon {...iconStyle} />,
  [AssetType.Other]: <PowerSettingsNewIcon {...iconStyle} />,
  [AssetType.RotaryKiln]: <ClinkerIcon {...iconStyle} />,
  [AssetType.RawMill]: <CementIcon {...iconStyle} />,
  [AssetType.CementMill]: <CementIcon {...iconStyle} />,
  [AssetType.BaseLoad]: <PowerSettingsNewIcon {...iconStyle} />,
  [AssetType.CoalMill]: <CementIcon {...iconStyle} />
}

interface AomAssetIconProps extends BoxProps {
  assetType: AssetType
  aomType: OperationModeType
  isTransition: boolean
  isFixed?: boolean
}

const _AomAssetIcon = React.forwardRef<HTMLDivElement, AomAssetIconProps>(
  ({assetType, aomType, isTransition, isFixed = false, ...boxProps}, ref) => {
    const getIcon = () => {
      if (isFixed && aomType !== OperationModeType.Maintenance)
        return <LockOutlined {...dataTestId('is_fixed_icon')} />
      if (isTransition) return <SwapHorizIcon fill={HCEMPalette.deepOcean} />
      if (aomType === OperationModeType.Maintenance)
        return <BuildOutlined {...iconStyle} {...dataTestId('maintenance_icon')} />
      return AssetToIcon[assetType]
    }
    return (
      <Box
        ref={ref}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1}}
        {...boxProps}
      >
        {getIcon()}
      </Box>
    )
  }
)

export const AomAssetIcon = React.memo(_AomAssetIcon)
