import {Localization} from '@hconnect/uikit/src/common'

const supportedLngs = [
  'de',
  'en-US',
  'en-GB',
  'ru',
  'it',
  'fr',
  'cs',
  'hu',
  'nl',
  'se',
  'es',
  'ro',
  'no',
  'pl'
]

const isDev = process.env.NODE_ENV === 'development'

const loader = async (filename: string) => {
  if (isDev) {
    console.info(`Load Translation for hcem [${filename}]`)
  }
  try {
    return (await import(`./${filename}`)).default
  } catch (err) {
    if (isDev) {
      console.error(`Could not load Translation [${filename}]`, err)
    }
    return {err}
  }
}

export const initLocalization = () => {
  void Localization({
    supportedLngs,
    backend: {loader},
    debug: isDev,
    fallbackLng: 'en-GB',
    compatibilityJSON: undefined
  })
}
