import {Add, Remove} from '@mui/icons-material'
import {IconButton, SxProps, TextField, Theme} from '@mui/material'
import React, {ChangeEvent} from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'

interface InputStepperProps {
  value: string
  onChange: (value: string) => void
  label: string
  onIncrease: () => void
  onDecrease: () => void
  isIncreaseDisabled?: boolean
  isDecreaseDisabled?: boolean
  placeholder?: string
  sx?: SxProps<Theme>
  'data-test-id'?: string
}

export const InputStepper: React.FC<InputStepperProps> = ({
  value,
  onChange,
  label,
  onIncrease,
  onDecrease,
  isIncreaseDisabled = false,
  isDecreaseDisabled = false,
  placeholder = '',
  sx,
  ['data-test-id']: testId
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      label={label}
      InputProps={{
        startAdornment: (
          <IconButton
            disabled={isDecreaseDisabled}
            onClick={onDecrease}
            {...dataTestId('decrease_btn')}
          >
            <Remove />
          </IconButton>
        ),
        endAdornment: (
          <IconButton
            disabled={isIncreaseDisabled}
            onClick={onIncrease}
            {...dataTestId('increase_btn')}
          >
            <Add />
          </IconButton>
        )
      }}
      onChange={handleChange}
      sx={sx}
      inputProps={{sx: {textAlign: 'center'}}}
      {...dataTestId(testId ?? 'input_stepper')}
    />
  )
}
