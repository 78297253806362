import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {Alert} from '@hconnect/uikit/src/lib2'
import {DeleteOutline, Close} from '@mui/icons-material'
import {Button, Skeleton, Stack, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {removeHcemQuery} from '../../../../../shared/api/queryHelpers'
import {useDialog} from '../../../../../shared/components/providers/DialogProvider'
import {AssetOperationTimeSource} from '../../../../../shared/enums'
import {useRemoveAutofillItems} from '../../../../../shared/hooks/api/schedules/useRemoveAutofillItems'
import {useScheduleQuery} from '../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {ScheduleItemDict} from '../../../../../shared/interfaces/api'
import {MomentRange} from '../../../../../shared/selectors/time'

const getScheduleItemsToBeRemoved = (schedules: ScheduleItemDict, range: MomentRange) => {
  return Object.values(schedules).filter(
    (item) =>
      item.source === AssetOperationTimeSource.Optimizer &&
      item.isFixed === false &&
      moment.utc(item.start).isBetween(range[0], range[1]) &&
      moment.utc(item.end).isBetween(range[0], range[1])
  )
}

export const AutofillRemovalWarningStep: React.FC<{
  selectedRange: MomentRange
}> = ({selectedRange}) => {
  const {t} = useTranslation()
  const {closeDialog} = useDialog()
  const plantCode = useUrlParam('plantCode')
  const {data: schedule, isLoading} = useScheduleQuery({isOptimized: false, range: selectedRange})

  const {mutate: removeAutofillItems} = useRemoveAutofillItems()

  const scheduleItemsToBeRemoved = getScheduleItemsToBeRemoved(
    schedule?.schedules ?? {},
    selectedRange
  )

  // removing query to avoid refetching the schedule when invalidated
  const removeScheduleQuery = () => {
    removeHcemQuery('schedule', {
      plantCode,
      start: selectedRange[0].toISOString(),
      end: selectedRange[1].toISOString()
    })
  }

  const onCancel = () => {
    // removing query here to ensure query always fetched when dialog is opened
    removeScheduleQuery()
    closeDialog()
  }

  const onRemoveAutofillItems = () => {
    removeAutofillItems(
      {
        plantCode,
        start: selectedRange[0].toISOString(),
        end: selectedRange[1].toISOString()
      },
      {onSuccess: removeScheduleQuery}
    )
    closeDialog()
  }

  return (
    <Stack spacing={2} {...dataTestId('autofill_removal_warning_step')}>
      <Typography variant="body1">{t('planning.removeAutofillItemsWarningDescription')}</Typography>
      {schedule ? (
        <Alert
          accentColor="warning"
          content={t('planning.removeAutofillItemsWarning', {
            count: scheduleItemsToBeRemoved.length
          })}
          {...dataTestId('autofill_removal_warning')}
        />
      ) : (
        <Skeleton variant="rectangular" height={46} />
      )}
      <Stack direction="row" spacing={1.5} justifyContent="flex-end">
        <Button
          color="secondary"
          onClick={onCancel}
          startIcon={<Close />}
          {...dataTestId('confirm_dialog_cancel_button')}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={isLoading}
          onClick={onRemoveAutofillItems}
          startIcon={<DeleteOutline />}
          {...dataTestId('remove_autofill_items_btn')}
        >
          {t('common.remove')}
        </Button>
      </Stack>
    </Stack>
  )
}
